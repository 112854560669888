.title {
  font-size: var(--mantine-font-size-sm);
  font-weight: 500;
  color: var(--mantine-color-gray-11);
}

.body {
  color: var(--mantine-color-gray-10);
}

.overlay {
  background: var(--mantine-color-gray-a-10);
}
