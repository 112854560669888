.control {
  border: 1px solid transparent;
  font-weight: 450;

  &:not([data-disabled='true']) {
    &:hover {
      background: var(--mantine-color-gray-a-2);
    }
  }

  &:not([data-active='true']) {
    color: var(--mantine-color-gray-10);
  }

  &:where([data-active]) {
    border-color: var(--mantine-color-gray-6);
    box-shadow: var(--mantine-shadow-md);
  }
}
