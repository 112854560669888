.tabRoot {
  --tab-vertical-padding: var(--mantine-spacing-sm);
}

.tabList {
  padding: var(--tab-vertical-padding) var(--mantine-spacing-xs);
  gap: var(--mantine-spacing-xs);
  position: relative;

  &::before {
    border-bottom-width: 1px;
  }
}

.tabButton {
  border-radius: var(--mantine-radius-sm);
  padding: 0 6px;
  height: 28px;
  font-weight: 500;
  color: var(--mantine-color-gray-10);
  border: 1px solid transparent;

  &:hover {
    background: var(--mantine-color-gray-a-2);
    color: var(--mantine-color-gray-11);
  }

  &:focus-visible {
    border-color: var(--mantine-color-blue-8);
  }

  &[data-active='true'] {
    color: var(--mantine-color-gray-11);

    &::before {
      content: '';
      position: absolute;
      bottom: calc(-1 * var(--tab-vertical-padding));
      width: calc(100% - 16px);
      height: 2px;
      background: var(--mantine-color-blue-8);
      border-radius: 2px 2px 0px 0px;
    }
  }
}
