.tableHeader {
  background: var(--mantine-color-gray-0);
}

.tableHeaderCell {
  color: var(--mantine-color-gray-10);
  font-weight: 450;
}

.tableHeader {
  .tableRow {
    &:hover {
      background: transparent;
    }
  }
}

.tableRow,
.tableHeader {
  border-bottom: 1px solid var(--mantine-color-gray-2);
}

.tableRow td,
.tableHeader th {
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-sm);
}

/* 
  https://www.figma.com/design/26iNUumqe5stxsqODPmcy0?node-id=2602-34040&m=dev#899532622 
  hardcoding 24 as I am not yet faimliar with Mantines spacing system
*/
.tableRow td:first-child,
.tableHeader th:first-child {
  padding-left: 24px;
}

.tableRow td:last-child,
.tableHeader th:last-child {
  padding-right: 24px;
}
