/* Override focus visible for components */
.mantine-focus-auto {
  &:focus-visible {
    outline: none;
    box-shadow: var(--mantine-shadow-focus-visible);
  }
}

:root[data-mantine-color-scheme='light'] {
  --mantine-color-placeholder: var(--mantine-color-gray-9);
  --mantine-color-error: var(--mantine-color-red-9);
}
