.dropdown {
  --combobox-padding: 0;
  border-radius: var(--mantine-radius-md);
  box-shadow: var(--mantine-shadow-md);
  border: 1px solid var(--mantine-color-gray-6);
  overflow: hidden;
}

.groupLabel {
  color: var(--mantine-color-gray-10);

  &::after {
    background: var(--mantine-color-gray-5);
  }
}

/* ⚠️ HACK to increase the specifity to prevent Input styles overriding focus and border */
.search.search {
  width: 100%;
  border: 0px;
  border-bottom: 1px solid var(--mantine-color-gray-6);
  box-shadow: none;

  &:focus,
  &:hover {
    border: 0px;
    box-shadow: none;
    border-bottom: 1px solid var(--mantine-color-gray-6);
  }
}

.options {
  padding: var(--mantine-spacing-2xs);
}

.option {
  padding: var(--mantine-spacing-2xs);
  border-radius: var(--mantine-radius-sm);
  font-weight: 450;

  &:where([data-combobox-selected]) {
    background: var(--mantine-color-gray-a-2);
    color: var(--mantine-color-gray-11);
  }

  &:hover {
    background: var(--mantine-color-gray-a-2);
  }
}
