.stepIcon {
  border-radius: var(--mantine-radius-sm);
  font-size: 13px;
  font-weight: 500;

  &[data-progress='true'] {
    color: var(--mantine-color-gray-11);
    border: 1px solid var(--mantine-color-gray-a-5);
    background: transparent;
  }

  &[data-completed] {
    border: 1px solid transparent;
  }
}

.steps {
  padding: var(--mantine-spacing-md);
  border-bottom: 1px solid var(--mantine-color-gray-5);
}

.content {
  padding: var(--mantine-spacing-md);
}

.stepLabel {
  font-weight: 500;
  font-size: 14px;
}

.step {
  border: 1px solid transparent;
  padding-right: 4px;

  &[data-progress='true'] {
    .stepLabel {
      color: var(--mantine-color-gray-11);
    }
  }

  &:not([data-progress]) {
    color: var(--mantine-color-gray-10);
  }

  &:focus-visible {
    border-radius: var(--mantine-radius-sm);
    border-color: var(--mantine-color-blue-9);
  }
}
