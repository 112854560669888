[data-mantine-color-scheme='light'] {
  .track {
    --switch-bg: var(--mantine-color-gray-8);
    --switch-bd: var(--mantine-color-gray-9);
    box-shadow: var(--mantine-shadow-md-inner);

    &:hover {
      --switch-bg: var(--mantine-color-gray-9);
    }
  }

  .thumb {
    --switch-thumb-bd: transparent;
    box-shadow: var(--mantine-shadow-md);
  }

  .input:focus-visible + .track {
    outline: none;
    box-shadow: var(--mantine-shadow-focus-visible);
  }

  .input:checked + .track {
    --switch-bd: var(--mantine-color-blue-9);

    &:hover {
      --switch-bg: var(--mantine-color-blue-9);
    }
  }
}
