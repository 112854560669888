.root {
  --avatar-size-xs: 18px;
  --avatar-size-sm: 22px;
  --avatar-size-md: 28px;

  /* Avatar font size variable, keeping minimum 10px */
  --avatar-placeholder-fz: max(calc(var(--avatar-size) / 2), 10px);

  border: 1px solid var(--mantine-color-gray-5);

  &:where([data-within-group]) {
    border: 1px solid var(--mantine-color-gray-5);
  }
}

.placeholder {
  font-weight: 500;
}
