.root {
  font-weight: 450;

  --button-height-compact-sm: 28px;
  --button-height-sm: 32px;
}

.root {
  &[data-variant='filled'] {
    box-shadow: var(--mantine-shadow-md-inner);
  }

  &[data-variant='subtle'] {
    &:focus-visible {
      background: var(--button-hover);
    }

    &[data-disabled='true'] {
      background: transparent;
    }
  }

  &[data-disabled='true'] {
    border-color: transparent;
    box-shadow: none;
  }

  /* Disable transform on active styles */
  &:active {
    transform: none;
  }

  /* Padding between section and border */
  &:where([data-with-left-section]) {
    padding-inline-start: var(--button-padding-x);
  }

  &:where([data-with-right-section]) {
    padding-inline-end: var(--button-padding-x);
  }

  /* Margin between section and content */
  &[data-size='sm'] {
    .section {
      &:where([data-position='left']) {
        margin-inline-end: 6px;
      }

      &:where([data-position='right']) {
        margin-inline-start: 6px;
      }
    }
  }

  &[data-size='compact-sm'] {
    .section {
      &:where([data-position='left']) {
        margin-inline-end: 4px;
      }

      &:where([data-position='right']) {
        margin-inline-start: 4px;
      }
    }
  }
}

/* Custom styles for red button, we need red box shadow */
.root-red {
  &[data-variant='outline']:hover {
    border: 1px solid var(--mantine-color-red-a-7);
  }

  &[data-variant] {
    &:focus-visible {
      box-shadow:
        0px 0px 0px 2px var(--mantine-color-red-a-5),
        0px 1px 1px 1px rgba(255, 255, 255, 0.2) inset,
        0px 1px 2px -1px var(--mantine-color-gray-a-2, rgba(0, 0, 0, 0.04)),
        0px 1px 3px -1.5px var(--mantine-color-gray-a-2, rgba(0, 0, 0, 0.04));
      outline: none;
    }
  }
}
