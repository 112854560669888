.divider,
.dropdown,
.arrow {
  border-color: var(--mantine-color-gray-6);
}

.dropdown {
  padding: 0;
  border-radius: var(--mantine-radius-md);
}

.item {
  font-size: 14px;
  width: calc(100% - var(--mantine-spacing-xs));
  margin: 0 auto;
  padding: 4px;
}

.item:first-of-type {
  margin-top: calc(var(--mantine-spacing-xs) / 2);
}

.item:last-of-type {
  margin-bottom: calc(var(--mantine-spacing-xs) / 2);
}

.itemLabel {
  color: var(--mantine-color-gray-11);
}

.itemSection {
  color: var(--mantine-color-gray-9);
}

.divider {
  margin: calc(var(--mantine-spacing-xs) / 2) 0;
}
