.icon {
  --ti-size-xs: 14px;
  --ti-size-sm: 16px;
  --ti-size-md: 20px;
  --ti-size-lg: 24px;
  --ti-size-xl: 28px;

  border-radius: 0;

  &[data-size='sm'] {
    svg {
      stroke-width: 1.5px;
    }
  }

  &[data-size='xs'] {
    svg {
      stroke-width: 1.25px;
    }
  }

  &[data-size='md'] {
    svg {
      stroke-width: 1.75px;
    }
  }
}
