.metaContainer {
  align-items: center;
  flex-wrap: nowrap;
}

.mappingMetaContainer {
  flex-wrap: nowrap;
  overflow: hidden;
}

.tableWithNoLeftRightBorder {
  tr td:first-child {
    border-left: 0;
  }

  tr th:first-child {
    border-left: 0;
  }

  tr td:last-child {
    border-right: 0;
  }

  tr th:last-child {
    border-right: 0;
  }
  tr td {
    padding: 8px;
  }
}

.expandableRow {
  cursor: pointer;
}

.statCardContainer {
  border: 1px solid var(--mantine-color-gray-7);
  border-radius: var(--mantine-radius-sm);
  width: 280px;
  min-height: 90px;
  background: white;
  display: flex;
  justify-content: center;
}

.statIconContainer {
  border: 1px solid var(--mantine-color-gray-5);
  border-radius: 100%;
  background: white;
  padding: var(--mantine-spacing-2xs);
}

@keyframes rotation {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.statLoader {
  animation: rotation;
  flex-shrink: 0;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-timing-function: linear;
}

.stepperNumberActive,
.stepperNumberInactive {
  width: 20px;
  text-align: center;
  font-size: 13px;
  line-height: 21px;
  font-weight: 500;
  border-radius: 6px;
  margin-right: 8px;
}

.stepperNumberInactive {
  color: var(--mantine-color-gray-11);
  background-color: var(--mantine-color-gray-3);
}

.stepperNumberActive {
  color: var(--mantine-color-gray-11);
  background-color: white;
}

.previewSyncIcon {
  width: 28px;
  height: 28px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid var(--mantine-color-gray-6);
}

.duplicateRecordsTableContainer {
  overflow-y: auto;
}

.duplicateRecordsTable {
  table-layout: fixed;

  thead {
    th {
      width: 50%;
    }
  }

  tbody {
    tr {
      td {
        width: 1px; /* divide the width equally */

        /* Show overflow */
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      td:not(:first-child) {
        /** Highlight the error columns */
        background-color: var(--mantine-color-red-1);
      }
    }
  }
}

.advancedRecordMatchingModalBody {
  padding: 0;
  border-top: 1px solid var(--mantine-color-default-border);
}

.dashedLink {
  color: var(--mantine-color-gray-10);
  text-decoration-style: dashed;
  text-decoration-color: var(--mantine-color-gray-7);
  text-underline-offset: 5px;

  &:hover {
    &,
    & * {
      color: var(--mantine-color-blue-8) !important;
      text-decoration-color: var(--mantine-color-blue-6) !important;
    }
  }

  *[data-error='true'] {
    color: var(--mantine-color-red-10);
  }
}

.nonLinkMappingResultText {
  color: var(--mantine-color-gray-10);
  *[data-error='true'] {
    color: var(--mantine-color-red-10);
  }
}
