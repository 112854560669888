.input {
  font-weight: 450;

  &:hover {
    border: 1px solid var(--mantine-color-gray-7);
  }

  &:focus {
    border: 1px solid var(--mantine-color-blue-9);
    box-shadow: var(--mantine-shadow-focus-visible);
  }

  &:disabled {
    border: 1px solid var(--mantine-color-gray-6);
    background: var(--mantine-color-gray-a-1);
    box-shadow: var(--mantine-shadow-md);
    color: var(--mantine-gray-a-11);
  }

  &[data-error='true'] {
    border: 1px solid var(--mantine-color-red-8);
    color: var(--mantine-color-red-10);

    &::placeholder {
      color: var(--mantine-color-gray-6);
    }

    &:focus {
      border: 1px solid var(--mantine-color-red-9);
      box-shadow: var(--mantine-shadow-error-focus-visible);
    }
  }
}
