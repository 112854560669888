.root {
  &[data-variant='filled'] {
    box-shadow: var(--mantine-shadow-md-inner);
  }

  &[data-variant='subtle'] {
    &:focus-visible {
      background: var(--ai-hover);
    }
  }

  &[data-disabled='true'] {
    border-color: transparent;
    box-shadow: none;
  }

  &:active {
    transform: none;
  }

  &[data-size='xs'] {
    width: 26px;
    height: 26px;

    /* svg {
      width: 14px;
      height: 14px;
    } */
  }

  &[data-size='sm'] {
    width: 32px;
    height: 32px;

    /* svg {
      width: 16px;
      height: 16px;
    } */
  }
}
